import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {
  Message,
  Tabs,
  TabPane,
  Carousel,
  CarouselItem,
  Pagination,
  Row,
  Col,
} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";

Vue.use(BootstrapVue);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Pagination);
Vue.use(Row);
Vue.use(Col);
Vue.config.productionTip = false;

axios.defaults.headers.post["Content-Type"] = "application/json";
// 添加请求拦截器
axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function(response) {
    if (response.data.returnCode == 401) {
      router.replace({ name: "Code" });
    }

    return response;
  },
  function(error) {
    return error;
  }
);

// message.config({
//   duration: 2,
//   maxCount: 1,
// });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
