<template>
    <header>
        <b-navbar id="Header" toggleable="lg" type="light" variant="light">
            <b-navbar-brand to="./">
                <img class="logo" src="../assets/header/logo.png" alt="logo" />
            </b-navbar-brand>

            <!-- <div class="search search-md">
                <img src="../assets/header/search.png" alt="search" />
            </div> -->

            <b-navbar-toggle
                class="navbarToggle"
                target="nav-collapse"
                style="margin-left: auto"
            ></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav id="navbar" class="ml-auto" no-gutters>
                    <b-nav-item
                        v-for="(item, index) in navItemList"
                        :key="index"
                        :to="item.to"
                        :class="{ active: index == $store.state.switchIndex }"
                        >{{ item.name }}</b-nav-item
                    >
                </b-navbar-nav>
            </b-collapse>

            <!-- <i class="verticalLine"></i>
            <div class="search search-lg">
                <img src="../assets/header/search.png" alt="search" />
            </div> -->
        </b-navbar>
        <div class="header-space"></div>
    </header>
</template>

<script>
import { navItemList } from "@/baseData/index";
export default {
    data() {
        return {
            navItemList,
        }
    },
}
</script>

<style lang="less" scoped>
.header-space {
    height: 82px;
}
#Header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 82px;
    line-height: 82px;
    padding: 0 60px;
    background-color: #fff !important;
    // z-index: 10;
    .logo {
        width: 150px;
    }
    .navbar-brand {
        padding-top: 0;
        padding-bottom: 0;
    }
    .navbar-toggler {
        font-size: 2rem;
    }
    #navbar {
        font-size: 1.8rem;
        .nav-item {
            position: relative;
            padding: 0 10px;
            margin: 0 28px;
            text-align: center;
            overflow: hidden;
            .nav-link {
                padding: 0;
                color: #000 !important;
            }
            border-bottom: 2px solid transparent;
            // &::after {
            //     content: "";
            //     position: absolute;
            //     bottom: -4px;
            //     left: 0;
            //     width: 100%;
            //     height: 2px;
            //     // background-color: transparent;
            //     background-color: var(--base-blue);
            //     transition: 0.3s ease;
            // }
            &.active {
                border-bottom: 2px solid #015aab;
                .nav-link {
                    color: #015aab !important;
                }
                // &::after {
                //     background-color: var(--base-blue);
                //     bottom: 1px;
                // }
            }
            &:hover {
                border-bottom: 2px solid #015aab;
                .nav-link {
                    color: #015aab !important;
                }
                // &::after {
                //     bottom: 1px;
                // }
            }
        }
    }
    .verticalLine {
        width: 1px;
        height: 30px;
        background-color: #efefef;
        margin-left: 20px;
        margin-right: 50px;
    }
    .search {
        --w: 4rem;
        position: relative;
        width: var(--w);
        height: var(--w);
        line-height: var(--w);
        text-align: center;
        background-color: #efefef;
        border-radius: 100%;
        img {
            --iw: 1.5rem;
            width: var(--iw);
            height: var(--iw);
        }
    }
    .search-md {
        display: none;
        margin-left: auto;
    }
}
@media screen and (max-width: 991px) {
    #Header {
        padding: 0 20px;
        .search-md {
            display: block;
        }
        .search-lg {
            display: none;
        }
        .navbar-toggler {
            margin-left: 20px !important;
        }
        #navbar {
            background: #fff;
            border: 1px solid #efefef;
        }
        .verticalLine {
            display: none;
        }
    }
}
</style>
