const navItemList = [
  {
    to: "/",
    name: "首页",
    children: [],
  },
  {
    to: "/product",
    name: "产品介绍",
    children: [
      {
        to: "/productDetail",
        name: "产品详情",
      },
    ],
  },
  // {
  //   to: "/cooperation",
  //   name: "商务合作",
  //   children: [],
  // },
  {
    to: "/company",
    name: "公司介绍",
    children: [],
  },
  {
    to: "/connectUs",
    name: "联系我们",
    children: [],
  },
];

const footInfoList = [
  {
    icon: "icon-dizhi",
    content: "公司地址：长春市朝阳区前进大街996号力旺广场B座16楼",
  },
  {
    icon: "icon-dianhua",
    content: "联系电话：0431 - 81884046",
  },
  {
    icon: "icon-zixun",
    content: "商务咨询：business@lawsdata.com",
  },
  {
    icon: "icon-zhaopin",
    content: "人才招聘：jobs@lawsdata.com",
  },
];

const concatUsInfoList = [
  {
    icon: "icon-phone",
    text: "联系电话：0431-81884046",
  },
  {
    icon: "icon-youxiang1",
    text: "公司邮箱：feedback@lawsdata.com",
  },
  {
    icon: "icon-zixun1",
    text: "商务咨询：business@lawsdata.com",
  },
  {
    icon: "icon-zhaopin1",
    text: "人才招聘：jobs@lawsdata.com",
  },
  {
    icon: "icon-gongzhonghao",
    text: "社交媒体：法律数据实验室",
  },
  {
    icon: "icon-didian",
    text: "公司地址：长春市朝阳区前进大街996号力旺广场B座16楼",
  },
];

const companyList = [
  {
    text: 2015,
    suffix: "年",
    postfix: "成立于 / since",
  },
  {
    text: 80,
    suffix: "余人",
    postfix: "优秀员工",
  },
  {
    text: 10,
    suffix: "余省份",
    postfix: "产品遍布全国",
  },
  {
    text: 20,
    suffix: "余项",
    postfix: "著作权及专利",
  },
];
const companyImg = [
  "2023年3月24日，长春市把手科技有限公司创始人刘思铭在“创业奋斗‘就’在吉林”大学生创业就业展示交流活动暨大学生创业就业座谈会上分享创业心得。",
  "2023年3月24日，把手科技智能法律咨询机器人产品在吉林大学创新创业教育展展出。",
  "2023年3月24日，把手科技作为招聘单位参加由吉林省人社厅、吉林省教育厅、吉林大学共同主办的吉林省高校毕业生留省就业大型招聘活动暨“万企进校园”吉林大学毕业生春季双选会，并受到省内媒体关注报道。",
  "2022年8月21日，把手科技创始人刘思铭受邀参加第十届全国社会媒体处理大会（SMP），分享智慧司法相关技术研究。",
  "2022年7月18日，把手科技与君泽君长春律所达成合作，君泽君智慧法务实验室正式揭牌。",
  "向前吉林省委书记、省人大常委会主任巴音朝鲁介绍“把手案例”网站",
  "向河南省省委副书记、省长王凯介绍”把手案例“网站",
  "向吉林市委副书记、市长王路介绍“把手案例”网站",
  "吉林大学法律硕士”法律人工智能方向“研究生培养实践基地",
  "参加清华大学《迈向数据法学》研讨会",
  "获得第十届“挑战杯”中国大学生创业大赛全国金奖",
  "参加国家重点研发计划《智慧司法智能化认知技术研究》答辩会议",
  "做客第二届长春国际创客节",
  "吉林省青年企业家协会第九次会员大会",
];
const productDetailList = [
  {
    title: "全国法律法规数据库",
    href: "https://www.lawsdata.com/#/home",
    text: [
      "法律法规库以各级人大、司法机关、行政机关网站为数据来源，收录有190多万篇法律、立法解释、行政法规、司法解释、部门规章、规范性文件等国家级的法律文件，以及地方性法规、规章和规范性文件。",
      " 法律法规库通过对法律法规文件进行结构化处理、清洗、要素提取和关联，能准确提取效力级别、发布机关、时效性、领域分类、发布日期等属性数据，同时还能实现对新发布的法律法规以及已修订或失效的法律法规数据进行实时更新。",
    ],
    imgList: [require("../assets/productDetail/p-1-1.jpg"), require("../assets/productDetail/p-1-2.png")],
    arrange: [2, 2],
  },
  {
    title: "全国司法案例数据库",
    href: "https://www.lawsdata.com/#/home",
    text: [
      "裁判文书库 收录有1亿2000多万篇全国法院上网公开裁判文书，包含指导案例、公报案例、典型案例以及全国各法院公开的普通案例，文书类型包括判决书、裁定书、决定书、通知书、支付令等各种类型的裁判文书。",
      "检察文书库 收录有560多万篇检察院公开的检察文书，文书类型包括起诉书、抗诉书、不起诉决定书和刑事申诉复查决定书等。",
      "通过对裁判文书和检察文书数据进行结构化处理、清洗、要素提取、分析和关联，能准确提取案件类型、案由、当事人信息、事实情节、法律依据、裁判结果等多维度属性信息。",
      "同时，数据库搭载行业领先的高级搜索引擎和可视化图表分析引擎，能够支持复杂条件下的精准搜索和数据分析。",
    ],
    imgList: [
      require("../assets/productDetail/p-2-1.png"),
      require("../assets/productDetail/p-2-2.png"),
      require("../assets/productDetail/p-2-3.png"),
      require("../assets/productDetail/p-2-4.png"),
      require("../assets/productDetail/p-2-5.png"),
    ],
    arrange: [3, 3, 3, 2, 2],
  },
  {
    title: "全国行政处罚数据库",
    href: "www.lawsdata.com",
    text: [
      "行政处罚数据库收录了全国各级行政机关公开的行政处罚决定书。数据库为用户提供了高级检索功能、搜索结果可视化功能、关联法律依据查询功能等。用户可以在数据库中，根据筛选处罚事项、行政相对人类型、处罚决定时间、处罚类别等条件进行高级检索。",
    ],
    imgList: [require("../assets/productDetail/p-3-1.jpg"), require("../assets/productDetail/p-3-2.jpg"), require("../assets/productDetail/p-3-3.jpg"), require("../assets/productDetail/p-3-4.jpg")],
    arrange: [2, 2, 1, 1],
  },
  // 司法辅助工具
  {
    title: "法官留言微信小程序",
    href: "www.lawsdata.com",
    text: [
      "人民法院案件移动留言系统是当事人通过微信小程序与法官进行沟通交流、传递文件、了解案件进展的工具。系统使法官和当事人之间架起一座零障碍沟通的桥梁，既不耽误双方工作又能快捷、灵活地完成信息交流，避免了当事人因联系法官而来回奔波，减少了当事人诉累，彰显了“让数据多跑路，让群众少跑腿”的司法服务理念。同时，系统的统计数据将作为法官工作考核的依据。",
    ],
    imgList: [require("../assets/productDetail/p-15-1.png"), require("../assets/productDetail/p-15-2.png")],
    arrange: [2, 2],
  },
  {
    title: "刑事类案检索系统",
    href: "www.lawsdata.com",
    text: [
      "系统针对不同刑事案件，提供主体、犯罪构成事实、量刑情节、犯罪形态、裁判结果等多类标签供用户选择，根据用户所选信息推送与所选情节一致或者相似的案件裁判文书、检察文书，并自动对相似案例的刑期分布、量刑情节等进行可视化数据分析、数据对比分析。",
      "<span>系统特点：</span>",
      "<span>刑事全案由覆盖</span>",
      "类案数据库现已收录包含危害国家安全、危害公共安全、破坏社会主义市场经济秩序罪、侵犯公民人身权利、民主权利罪、侵犯财产罪、妨害社会管理秩序罪、危害国防利益罪、贪污贿赂罪、渎职罪共483个罪名在内的1100余万篇裁判文书、560万篇检察起诉书。",
      "<span>体系化类案标签点选搜索</span>",
      "提取案件主体、犯罪事实、量刑情节、损害结果、裁判结果信息，设置体系化标签筛选模块，用户可以通过点选方式搜索相似案例",
      "<span>事实情节精准识别</span>",
      "利用自然语言识别技术，通过人工标注进行有监督的机器学习，精准识别案情信息，排除干扰情形，情节识别准确率达99%。",
      "<span>类案数据深入分析</span>",
      "通过数据分析模型对检索的类案进行数据分析，包括刑期分布、主体特征、特殊犯罪情节、不同地区平均刑期对比等20多个分析维度，并可通过直接点选完成数据下钻。",
    ],
    imgList: [require("../assets/productDetail/p-4-1.png"), require("../assets/productDetail/p-4-2.png"), require("../assets/productDetail/p-4-3.png"), require("../assets/productDetail/p-4-4.jpg")],
    arrange: [2, 2, 2, 2],
  },
  {
    title: "刑事精准化量刑辅助系统",
    href: "www.lawsdata.com",
    text: [
      "根据最高人民法院发布的量刑指导意见、司法解释、各地方司法文件，对盗窃、抢劫、诈骗等常见刑事案件量刑情节和量刑幅度进行梳理，用户可根据不同犯罪类型选择不同事实情节、量刑情节、认罪认罚情况，确定增减比例，由系统自动给出量刑参考范围和计算方式。",
      "系统自动推送与所选情节相似的案例和量刑法律依据供用户参考。同时，系统结合类案检索系统，在计算刑期后自动对相似类案进行数据分析，推送相关案例和可视化数据分析结果。",
    ],
    imgList: [require("../assets/productDetail/p-5-1.png"), require("../assets/productDetail/p-5-2.png"), require("../assets/productDetail/p-5-3.png"), require("../assets/productDetail/p-5-4.png")],
    arrange: [2, 2, 2, 2],
  },
  {
    title: "裁判文书纠错",
    href: "www.lawsdata.com",
    text: [
      "法律文书纠错系统主要针对实践中裁判文书出现的质量问题，尤其是常见的文书格式、字词、语法、法律逻辑、引用法规等方面的错误而研发。系统充分考虑法院裁判文书特点和法官文书制作习惯，通过校验规则库对法律文书中的内容进行全面的检查，以提高裁判文书编写检查效率为目的，辅助提升裁判文书编写质量。",
      "目前，该系统包括文书纠错、文书上网屏蔽以及文书自动排版三大功能。可以对刑事、民事、行政的判决书、裁定书、调解书以及执行案件裁定书这几类裁判文书进行纠错、屏蔽处理和排版。纠错时会对文书中可能出现的错误给出“错误”和“提示”两种等级的判断，并在对应的错误项下给出具体依据。",
    ],
    imgList: [require("../assets/productDetail/p-6-1.png"), require("../assets/productDetail/p-6-2.png")],
    arrange: [2, 2],
  },
  {
    title: "把手法律助手",
    href: "www.lawsdata.com",
    text: [
      "把手法律助手是一款应用于司法工作者撰写法律文书场景的智能辅助工具。研究中发现，司法工作者总是需要花费大量时间和精力从繁杂的法律规范中进行法律适用的筛选。为解决这一痛点问题，把手法律助手基于司法工作者实际使用场景的需求，依托强大的大数据计算能力以及海量的数据资源，不仅能够实现法律规范的精确全面检索，还能大大提升法条检索的便捷性和效率。除此之外，把手法律助手对于法律法规的智能引用以及文本排版，亦实现了进一步优化，以辅助提升司法文书编写效率。",
      "目前，把手法律助手主要包含法规检索及引用、法规帮写、引文样式、全文调整、文书助手五大功能。可以对数百万法律法规数据实现一键引用、调整样式及其他辅助帮写功能，完美实现“一屏多功能”效果，避免多网页、多链接地检索和跳转，极大节省时间；搭载自研的高性能搜索引擎，切实贴合实际业务场景，兼顾数据的准确性与全面性，最大限度满足用户对法规的检索、引用等需求。",
    ],
    imgList: [
      require("../assets/productDetail/p-16-1.jpg"),
      require("../assets/productDetail/p-16-2.jpg"),
      require("../assets/productDetail/p-16-3.jpg"),
      require("../assets/productDetail/p-16-4.jpg"),
    ],
    arrange: [2, 2, 2, 2],
  },
  //行政辅助应用
  // {
  //     title: '法官留言小程序',
  //     href: 'www.lawsdata.com',
  //     text: ['法律法规库以各级人大、司法机关、行政机关网站为数据来源，收录有170多万篇法律、立法解释、行政法规、司法解释、部门规章、规范性文件等国家级的法律文件，以及地方性法规、规章和规范性文件。',
  //         ' 法律法规库通过对法律法规文件进行结构化处理、清洗、要素提取和关联，能准确提取效力级别、发布机关、时效性、领域分类、发布日期等属性数据，同时还能实现对新发布的法律法规以及已修订或失效的法律法规数据进行实时更新。'],
  //     imgList: [require('../assets/productDetail/p-15-1.png'), require('../assets/productDetail/p-15-2.png')],
  //     arrange: [2, 2]
  // },
  {
    title: "行政执法监督管理系统",
    href: "www.lawsdata.com",
    text: [
      "针对行政执法监管开发的应用系统，帮助行政执法机构完成“双随机”抽取检查对象和检查人员、发布检查任务、现场多人协作检查、拍照取证、法律依据与检查对象信息查询、出具检查报告、检查任务进度跟踪、检查任务信息统计等工作。系统具备简单易用、无需单独安装（微信+pc端操作）、协同操作、防止结果篡改和数据丢失等特点，能够提高行政执法工作质效。",
    ],
    imgList: [require("../assets/productDetail/p-8-1.jpg"), require("../assets/productDetail/p-8-2.jpg"), require("../assets/productDetail/p-8-3.jpg"), require("../assets/productDetail/p-8-4.jpg")],
    arrange: [2, 2, 2, 2],
  },
  // 企业风控系统
  {
    title: "企业法律风险自检系统",
    href: "www.lawsdata.com",
    text: [
      "企业法律风险自检系统针对企业在劳动人事、公司治理、对外经营、财税、知识产权等方面通常发生的风险，亦可以行业为区分，通过自查系列问答模式，获取企业的风险信息，自动分析生成风险清单、风险评估、风险预发提示、风险防控建议和行动指引，并推送法律依据和相似案例供企业参考。",
      "<span>风险1清单</span>",
      "根据企业自测情况，系统提供基于400多个企业常见法律风险点智能推送的风险分析及防范建议，并基于自有法律数据库，推送真实涉诉案例与法律法规条文。",
      "<span>风险评估</span>",
      "在风险清单的基础上，基于企业提供的信息，对每一个风险点的风险等级进行评估。风险等级包括三级：低风险、中风险和高风险。",
      "<span>风险预发提示</span>",
      "在风险清单的基础上，对每一个风险点可能发生的不同情况和损害后果进行详细解析和提示。",
      "<span>风险防控建议及行动指引</span>",
      "在风险清单的基础上，对每一个风险点提供相应的防控建议，以及防控风险的具体行动指引。",
      "<span>法律依据</span>",
      "系统自动推送与风险判定、风险可能发生的后果、风险防控相关的法律法规、地方法规、规章和其他规范性文件及具体条款，供用户参考。",
      "<span>相似案例</span>",
      "系统自动推送与风险清单中风险点具有相同或相似事实要素的相似案例，以帮助用户了解相关风险发生的可能后果或者防控手段。",
    ],
    imgList: [require("../assets/productDetail/p-9-1.png"), require("../assets/productDetail/p-9-2.png"), require("../assets/productDetail/p-9-3.png"), require("../assets/productDetail/p-9-4.png")],
    arrange: [2, 2, 2, 2],
  },
  {
    title: "企业法律风险指引系统",
    href: "www.lawsdata.com",
    text: [
      "企业法律风险指引系统是针对企业日常生产经营可能引发的民事、行政或刑事的高发风险进行指引提示的系统。通过企业自主选择不同的指引目录，为企业提供相关风险提示、风险指引、法律适用等指引内容。",
      "系统帮助企业从对内管理、对外经营两方面把控法律风险，以精准化服务为目标，基于自身精准化服务定位，助力企业合规经营，推动构建良好营商环境。",
      "<span>产品功能</span>",
      "<span>合同签订与履行</span>",
      "关于合同主体方面的风险提示",
      "关于合同订立方面的风险提示",
      "关于合同履行方面的风险提示",
      "<span>对外融资担保</span>",
      "关于企业向银行等金融机构借款的风险提示",
      "关于企业民间借贷的风险提示",
      "关于企业其他融资方式的风险提示",
      "关于企业对外担保的风险提示",
      "<span>内部管理</span>",
      "关于企业设立及管理的风险提示",
      "关于企业内部人员管理的风险提示",
      "关于企业知识产权保护的风险提示",
      "<span>企业争议解决</span>",
      "关于企业涉诉及仲裁问题的风险提示",
      "关于企业执行问题的风险提示",
    ],
    imgList: [
      require("../assets/productDetail/p-9-01.png"),
      require("../assets/productDetail/p-9-02.png"),
      require("../assets/productDetail/p-9-03.png"),
      require("../assets/productDetail/p-9-04.png"),
    ],
    arrange: [2, 2, 2, 2],
  },
  {
    title: "常见法律问题智能咨询系统",
    href: "www.lawsdata.com",
    text: [
      "通过引导式问答对用户咨询请求的相关信息进行采集，常见法律问题智能咨询系统对用户的法律咨询意图、属性信息等进行识别与分析，自动匹配系统后台的法律知识库，实时生成法律意见书，为用户提供兼具针对性与专业性的法律咨询意见和后续行动指引。",
      "该系统现支持对离婚、继承、交通事故、民间借贷、劳动、工伤、网购、买房、租房等常见的法律问题的自助咨询。",
    ],
    imgList: [
      require("../assets/productDetail/p-10-1.png"),
      require("../assets/productDetail/p-10-2.png"),
      require("../assets/productDetail/p-10-3.png"),
      require("../assets/productDetail/p-10-4.png"),
    ],
    arrange: [2, 2, 2, 2],
  },
  {
    title: "常见法律文书自动生成系统",
    href: "www.lawsdata.com",
    text: [
      "通过引导式问答（为单选题、多选题和填空题）提供各类法律文书，包括起诉书、申请书以及常见合同的智能生成。",
      "该系统独立于法律咨询系统，用户无需咨询法律问题，直接就可进入文书系统获取相应文书，同时对于有法律咨询需求的用户，在法律意见书中，针对特定行动策略中可能涉及的文书/合同，在咨询系统中也可直接点击进入文书系统实现文书的智能生成。",
      "文书类别",
      "合同：智能法律咨询常见民事问题所涉及的相关协议，如离婚协议、借款协议、遗产分割协议、房屋租赁协议、二手房买卖协议等。",
      "企业：公司章程、公司发起设立协议、股权转让协议等。",
      "诉讼：智能法律咨询常见民事问题所涉及的民事起诉书及证据目录、财产保全申请书、证据保全申请书、强制执行申请书等。",
    ],
    imgList: [
      require("../assets/productDetail/p-11-1.png"),
      require("../assets/productDetail/p-11-2.png"),
      require("../assets/productDetail/p-11-3.png"),
      require("../assets/productDetail/p-11-4.png"),
    ],
    arrange: [2, 2, 2, 2],
  },
  {
    title: "类案检索服务",
    href: "www.lawsdata.com",
    text: [
      "<span>服务内容：</span>",
      "针对法官、检察官、律师、教师工作过程中遇到的具体法律问题和类案检索需求，依托中国裁判文书网公开的裁判文书，运用技术手段检索分析各地、各级别法院对于同一问题的裁判观点和倾向性意见，为用户提供参考。",
      "<span>工作流程：</span>",
      "提供案件有关材料或直接说明需要检索的问题→案情了解→制定检索条件→案例检索→与客户沟通反馈→整理案件、制作检索报告→提交报告→根据反馈意见补充或修改服务成果提供：案例检索报告(电子版) 、相关案件裁判文书(电子版)",
    ],
    imgList: [require("../assets/productDetail/p-15-1.jpg"), require("../assets/productDetail/p-15-2.jpg"), require("../assets/productDetail/p-15-3.jpg")],
    arrange: [3, 3, 3],
  },
  {
    title: "法律数据分析服务",
    href: "www.lawsdata.com",
    text: [
      "<span>服务内容：</span>",
      "政府、法院、法学院校、大型企业等客户根据行政管理、课题研究、企业管理需要，提出案件范围和内容要求，技术人员编写检索规则和检索程序，后在司法案例数据库中进行裁判文书检索、筛选，通过机器和人工相结合的方式提取判决文书中的所需信息，并对数据进行统计学分析。",
      "<span>工作流程：</span>",
      "提供案件范围和分析维度需求→制定数据分析报告大纲→筛选案例→信息统计→数据分析→报告编写→根据反馈意见补充或修改",
      "<span>服务成果提供：</span>",
      "1.数据分析报告",
      "2.课题研究报告,法院司法调研数据分析",
      "3.企业类案数据分析",
    ],
    imgList: [
      require("../assets/productDetail/p-12-1.jpg"),
      require("../assets/productDetail/p-12-2.jpg"),
      require("../assets/productDetail/p-12-3.jpg"),
      require("../assets/productDetail/p-13-1.jpg"),
      require("../assets/productDetail/p-13-2.jpg"),
      require("../assets/productDetail/p-13-3.jpg"),
      require("../assets/productDetail/p-14-1.jpg"),
      require("../assets/productDetail/p-14-2.jpg"),
      require("../assets/productDetail/p-14-3.jpg"),
    ],
    arrange: [3, 3, 3, 3, 3, 3, 3, 3, 3],
  },
];

const joinHandsList = [
  "吉林大学",
  "科大讯飞",
  // "国家市场监督管理总局认可与检验检测监督管理司",
  // "国家市场监督管理总局认证认可技术研究中心",
  "吉林省政法委",
  "山西省政法委",
  "安徽省政法委",
  "贵州省政法委",
  "新疆生产建设兵团政法委",
  "青海省政法委",
  "山西省高级人民法院",
  "安徽省高级人民法院",
  "贵州省高级人民法院",
  "新疆维吾尔自治区高级人民法院生产建设兵团分院",
  "青海省高级人民法院",
  "松原市中级人民法院",
  "德惠市人民法院",
  "长春市绿园区人民法院",
  "白山市中级人民法院", //新增的合作伙伴19
  "吉林省靖宇县人民法院",
  "吉林省抚松县人民法院",
  "长春市人民检察院",
  "本溪市人民检察院",
  "湖北省十堰市人民检察院",
  "永安市人民检察院",
  "黑龙江省人民检察院",
  "山西省人民检察院",
  "安徽省人民检察院",
  "贵州省人民检察院",
  "新疆生产建设兵团人民检察院",
  "青海省人民检察院",
  "晋江市人民检察院",
  "公主岭市人民检察院",
  "抚松县人民检察院",
  "深圳市福田区检察院",
  "白山市江源区人民检察院",
  "山西省公安厅",
  "安徽省公安厅",
  "贵州省公安厅",
  "青海省公安厅",
  "新疆生产建设兵团公安局",
  "深圳市公安局",
  "云南省红河州公安局",
  "安徽省司法厅",
  "青海省司法厅",
  "吉林省监狱管理局",
  "国家电网",
  "嘉诚信息",
  "联宇合达",
  "山东正极信息系统技术有限公司",
  "佐丹力",
  "瑞盘重整顾问（北京）有限公司",
  "深圳市神盾信息技术有限公司",
  "中山大学",
  "山东师范大学",
  "东北财经大学",
  "君泽君律师事务所",
  "大成律师事务所",
  "吉林兢诚律师事务所",

  // "深圳福田检察院",
  // "中信银行",
];

const newsList = [];

const cooperationList = {
  banner: [
    {
      title: "科大讯飞",
      text: [
        "科大讯飞股份有限公司成立于1999年，是亚太地区知名的智能语音和人工智能上市企业。该公司专注于智能语音、计算机视觉、自然语言处理、认知智能等人工智能核心技术的研究与开发。科大讯飞致力于让机器能够“能听会说，能理解会思考”，并通过其技术创新推动人工智能产品的研发和行业应用。",
        "自成立以来，科大讯飞在多个国际评测中取得优异成绩，如机器翻译、自然语言理解、图像识别等领域。此外，该公司还积极拓展全球市场，成为北京2022年冬奥会和冬残奥会的官方自动语音转换与翻译独家供应商，展示了其在语音翻译技术上的领先地位。",
        "科大讯飞不仅在技术上保持领先，还在教育、医疗、司法等多个领域进行了深入的行业应用，推动人工智能技术的落地。同时，公司通过开放平台，连接了数百万合作伙伴，共同构建了一个人工智能生态系统，推动了整个行业的快速发展。",
      ],
    },
    {
      title: "广州高新兴科技",
      text: [
        "高新兴科技集团股份有限公司成立于1997年，总部位于广州。该公司专注于智慧城市物联网产品与服务，致力于成为全球领先的智慧城市解决方案供应商。主要业务涵盖智慧城市、公共安全和垂直行业三大领域。通过物联网、人工智能、大数据和视频技术为核心技术，为科信、法制、交警、监管、治安、缉私等警种提供应用型产品和服务。集团在2010年成功上市，成为国家级高新技术企业。",
        "自成立以来，高新兴科技集团经历了从城市级安防建设企业向智慧城市运营商的转变，拥有全息智能技术和泛在通信技术两大核心共性技术，以这些技术为基础，通过融合大数据和人工智能等技术，提供全面的城市物联网解决方案。",
        "此外，高新兴科技集团还积极参与国家安全和社会治理，从交通出行到民生改善，公司的服务覆盖广泛，支持国家战略的实现。 ",
        // "高新兴科技集团股份有限公司成立于1997年，总部位于广州。该公司专注于智慧城市物联网产品与服务，致力于成为全球领先的智慧城市解决方案供应商。主要业务涵盖智慧城市、公共安全和垂直行业三大领域。通过物联网、人工智能、大数据和视频技术为核心技术，为科信、法制、交警、监管、治安、缉私等警种提供应用型产品和服务。集团在2010年成功上市，成为国家级高新技术企业。自成立以来，高新兴科技集团经历了从城市级安防建设企业向智慧城市运营商的转变，拥有全息智能技术和泛在通信技术两大核心共性技术，以这些技术为基础，通过融合大数据和人工智能等技术，提供全面的城市物联网解决方案。此外，高新兴科技集团还积极参与国家安全和社会治理，从交通出行到民生改善，公司的服务覆盖广泛，支持国家战略的实现。",
      ],
    },
    // {
    //   title: "深圳神盾科技",
    //   text: [
    //     "深圳市神盾科技有限公司成立于2004年12月13日，位于深圳市罗湖区黄贝街道新秀村秀南街十栋二楼A，目前处于注销状态，经营范围包括一般经营项目是：经营项目：化妆品、卫生用品的购销；五金交电、日用百货、纺织品、服装、家用电器、电子产品的批发和零售，信息咨询、企业投资咨询；货物进出口、技术进出口、计算机的软件、硬件及配件、网络设备、安防设备、技术开发、销售与维护。",
    //   ],
    // }
  ],
};

const cooperationPartner = [
  {
    name: "吉林省高级人民法院",
    iconIndex: 59,
  },
  {
    name: "吉林省政法委",
    iconIndex: 3,
  },
  {
    name: "安徽省司法厅",
    iconIndex: 42,
  },
  {
    name: "深圳市公安局",
    iconIndex: 40,
  },
  {
    name: "吉林大学",
    iconIndex: 1,
  },
  {
    name: "中山大学",
    iconIndex: 52,
  },
  {
    name: "松原市中级人民法院",
    iconIndex: 14,
  },
  {
    name: "白山市中级人民民法院",
    iconIndex: 60,
  },
  {
    name: "北京市君泽君(长春)律师事务所",
    iconIndex: 55,
  },
  {
    name: "长春市二道区人民法院",
    iconIndex: 61,
  },
  {
    name: "长春市绿园区人民法院",
    iconIndex: 16,
  },
  {
    name: "山东师范大学",
    iconIndex: 53,
  },
  {
    name: "长春市人民检察院",
    iconIndex: 20,
  },
  {
    name: "晋江市人民检察院",
    iconIndex: 30,
  },
  {
    name: "本溪市人民检察院",
    iconIndex: 21,
  },
  {
    name: "十堰市人民检察院",
    iconIndex: 58,
  },
  {
    name: "永安市人民检察院",
    iconIndex: 23,
  },
  {
    name: "云南省红河州公安局",
    iconIndex: 41,
  },
];

export { navItemList, footInfoList, concatUsInfoList, companyList, companyImg, productDetailList, joinHandsList, cooperationList, cooperationPartner };
